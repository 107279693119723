import * as React from "react"
import { Col, Row, Container } from "react-bootstrap"
//Components Import
import CallToAction from "../components/button/index"
import Header from "../components/header_v1"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import * as Styles from "../style/home.module.scss"
import Contactus from '../components/Contactus'
import * as Styles from "../style/contact.module.scss";

const ContactPage = () => {

  return (
    <Layout>
      <Seo title="Contact" />
      <div id="Home" className={Styles.banner_contactus}>
        <Header />
        <hr style={{ border: "#fff", height: "0.005em", color: "#ffffff", backgroundColor: "#ffffff" }} />
        <Container fluid >
          <Row>
            <Col md={3}></Col>
            <Col md={6} className={`${Styles.paddingTop150}`}>
              <div>
                <h1 className={`${Styles.fontWhite} ${Styles.bannerHeading}`}>
                  We are happy to hear from you
                </h1>

              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
          
        </Container>
      </div>
      <div className={Styles.Contactuspage}>
        <Container fluid className={Styles.contactus}>
          <Row>
            <Col md={2}></Col>
            <Col md={8} >
              <Contactus></Contactus>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
    </Layout>

  )
}
export default ContactPage
